<template>
  <CChartLine
    :datasets="datasets"
    :options="defaultOptions"
    :labels="labels"
    
  />
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getColor, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'DashboardIncomeExpenseChart',
  components: {
    CChartLine
  },
  props: ['datasets', 'labels', 'chartMax'],
  computed: {
    defaultOptions () {

      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(this.chartMax / 5),
              max: this.chartMax + 10
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
