<template>
  <CRow>
    <CCol>
      <CCardGroup class="mb-4">
        <CWidgetProgressIcon
          :header="'€' + generalStatistics.total_invoiced"
          text="Total Invoiced"
          color="info"
          inverse
        >
          <CIcon name="cil-money" height="36" />
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="'€' + generalStatistics.total_invoiced_paid"
          text="Total Paid"
          color="success"
          inverse
        >
          <CIcon name="cil-check" height="36" />
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="'€' + generalStatistics.total_invoiced_unpaid"
          text="Total Unpaid"
          color="warning"
          inverse
        >
          <CIcon name="cil-clock" height="36" />
        </CWidgetProgressIcon>
        <CWidgetProgressIcon
          :header="'€' + generalStatistics.total_invoiced_late"
          text="Total Late"
          color="danger"
          inverse
        >
          <CIcon name="cil-bell" height="36" />
        </CWidgetProgressIcon>
      </CCardGroup>
    </CCol>
  </CRow>
</template>

<script>
import CChartLineSimple from "../charts/CChartLineSimple";

export default {
  name: "Widgets",
  props: ["generalStatistics"],
  components: { CChartLineSimple },
};
</script>
