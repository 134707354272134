<template>
  <div>
    <CRow class="date-selectors">
      <CCol sm="3">
        <label class="datetime-label">From</label>
        <datetime
          format="MM-yyyy"
          type="date"
          value-zone="Europe/Amsterdam"
          zone="Europe/Amsterdam"
          placeholder="Date"
          label="From"
          v-model="filters.from"
          v-on:input="updateStatistics"
        ></datetime>
      </CCol>
      <CCol sm="3">
        <label class="datetime-label">Till</label>
        <datetime
          format="MM-yyyy"
          type="date"
          value-zone="Europe/Amsterdam"
          zone="Europe/Amsterdam"
          placeholder="Date"
          label="From"
          v-model="filters.till"
          v-on:input="updateStatistics"
        ></datetime>
      </CCol>
    </CRow>

    <Widgets :generalStatistics="generalStatistics" />
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Income</h4>
          </CCol>
          <!--          <CCol sm="7" class="d-none d-md-block">-->
          <!--            <CButton color="primary" class="float-right">-->
          <!--              <CIcon name="cil-cloud-download"/>-->
          <!--            </CButton>-->
          <!--            <CButtonGroup class="float-right mr-3">-->
          <!--              <CButton-->
          <!--                color="outline-secondary"-->
          <!--                v-for="(value, key) in ['Day', 'Month', 'Year']"-->
          <!--                :key="key"-->
          <!--                class="mx-0"-->
          <!--                :pressed="value === selected ? true : false"-->
          <!--                @click="selected = value"-->
          <!--              >-->
          <!--                {{value}}-->
          <!--              </CButton>-->
          <!--            </CButtonGroup>-->
          <!--          </CCol>-->
        </CRow>

        <DashboardIncomeExpenseChart
          :datasets="chartData.datasets"
          :labels="chartData.dataLabels"
          :chartMax="chartData.chartMax"
          style="height: 300px; margin-top: 40px"
        />
      </CCardBody>

      <CCardFooter>
        <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div class="text-muted">Total Sale</div>
            <strong>€ {{ chartData.totalSales }} </strong>
            <hr />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Rolly Share</div>
            <strong>€ {{ chartData.totalRollyShare }} </strong>
            <hr />
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import DashboardIncomeExpenseChart from "./DashboardIncomeExpenseChart";
import Widgets from "./Widgets";
import StatisticsAPI from "/app/src/api/statistics.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  name: "Dashboard",
  components: {
    DashboardIncomeExpenseChart,
    Widgets,
    Datetime,
  },
  created: function () {
    this.getGeneralStatistics();
    this.getChartData();
  },
  computed: {
    filters() {
      let filters = {};
      let currentYear = new Date().getFullYear();
      filters.from = currentYear + "-" + "01-01";
      filters.till = currentYear + "-12-31";
      return filters;
    },
  },
  data: () => {
    return {
      statisticsAPI: new StatisticsAPI(),
      generalStatistics: {
        total_invoiced: 0,
        total_invoiced_paid: 0,
        total_invoiced_unpaid: 0,
        total_invoiced_late: 0,
      },
      chartData: {
        labels: [],
        dataSets: [],
        totalSales: 0,
        totalRollyShare: 0,
      },
    };
  },
  methods: {
    updateStatistics: function() {
      this.getGeneralStatistics();
      this.getChartData();
    },
    getGeneralStatistics: function () {
      console.log(this.filters);
      this.statisticsAPI
        .generalAdmin(this.filters)
        .then((generalStatistics) => {
          this.generalStatistics = generalStatistics;
          console.log(generalStatistics);
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
        });
    },
    getChartData: function () {
      this.$store.dispatch("loading");
      console.log(this.filters);
      this.statisticsAPI
        .chartData(this.filters)
        .then((chartData) => {
          this.chartData = chartData;
          console.log("chartData:");
          console.log(chartData);
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.date-selectors {
  margin-bottom: 20px;
}
</style>
