import Base from "./base.js";

export default class Statistics extends Base {
  constructor() {
    super();
    this.route = "/api/statistics";
  }

  generalAdmin(filters) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: self.route + "/general-admin",
          data: filters,
          method: "POST",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data);
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data));
        });
    });
  }

  chartData(filters) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self
        .Axios({
          url: self.route + "/chart-data",
          data: filters,
          method: "POST",
        })
        .then((response) => {
          // Resolve successfully
          resolve(response.data);
        })
        .catch((error) => {
          reject(self.parseErrorResponses(error.response.data));
        });
    });
  }
}
